import React, { useContext } from 'react'
import Navbar from './Navbar';
import { LanguageContext } from './LanguageContext';

function Header() {

    const { getContent } = useContext(LanguageContext);
    const content = getContent();

    return (
        <div id="main">

            <Navbar />
            <div className='name' id='header'>
                <h2><span>{content.header1} </span>{content.header2} </h2>
                <p></p>
                <a href='#contactUs' className='cv-btn'>{content.contactUs}</a>
            </div>
        </div>
    )
}

export default Header;