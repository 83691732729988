import React, { useState, useContext, useEffect } from 'react'
import logo from "../images/cafecoquilogo.png"
import LanguageSwitch from './LanguageSwitch';
import { LanguageContext } from './LanguageContext';

function Navbar() {

    const { getContent } = useContext(LanguageContext);
    const content = getContent();

    const [nav, setnav] = useState(false);
    const [icon, setIcon] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);


    const changeSwitchLocation = () => {
        if (window.innerWidth <= 1100) {
            setIcon(true)
            setMenuOpen(false)
        }
        else {
            setIcon(false)
            setMenuOpen(true)
        }
    }
    const changeBackground = () => {
        if (window.scrollY >= 50) {
            setnav(true);

        }
        else {
            setnav(false);

        }
    }

    const handleMenuOptionClick = () => {
        if (window.innerWidth <= 1100) {
            setTimeout(() => {
                setMenuOpen(false);
              }, 200); // Delay of 200 milliseconds
        }
      };

      useEffect(() => {
        window.addEventListener('scroll', changeBackground);
        window.addEventListener('resize', changeSwitchLocation);
        return () => {
          window.removeEventListener('scroll', changeBackground);
          window.removeEventListener('resize', changeSwitchLocation);
        };
      }, []);

      useEffect(() => {
        // Initialize menu state based on screen width
        changeSwitchLocation();
      }, []);

    return (

        <nav className={nav ? 'nav active' : 'nav'}>
            <a href='#' className={nav ? 'logo' : 'logo menu-active'}>
                <img src={logo} alt='' />
            </a>
            {icon ? <LanguageSwitch /> : ''}
            <input 
            type='checkbox' 
            className='menu-btn' 
            id='menu-btn' 
            checked={menuOpen}
            onChange={() => setMenuOpen(!menuOpen)}
            />
            <label 
            className={nav ? 'menu-icon' : 'menu-icon menu-active'} 
            for="menu-btn"
            >
                <span className='nav-icon'></span>
            </label>


            <ul className={menuOpen ? 'menu' : 'menu menu-closed'}>

                <li><a href='#' onClick={handleMenuOptionClick}>{content.home}</a></li>
                <li><a href='#features' onClick={handleMenuOptionClick}>{content.features}</a></li>
                <li><a href='#About' onClick={handleMenuOptionClick}>{content.aboutUs}</a></li>
                <li><a href='#contactUs' onClick={handleMenuOptionClick}>{content.contactUs}</a></li>
                {icon ? '' : <LanguageSwitch />}
            </ul>

        </nav>
    )
}

export default Navbar;