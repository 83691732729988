import React from "react";
import Header from './Components/Header';
import Feature from './Components/Feature';
import About from './Components/About';

import Contact from './Components/Contact';



function App() {



  return (
    <div className="App">

      <Header />
      <Feature />
      <About />
      <Contact />

    </div>
  );
}

export default App;
