const validateEmail = ({ email, setEmailError }) => {
    const emailRegular = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    return email && !email.match(emailRegular)
        ? setEmailError("Email not Valid")
        : setEmailError('');
};

const validatePhone = ({ phone, setPhoneError }) => {
    const phoneRegular = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/
    return phone && !phone.match(phoneRegular)
        ? setPhoneError("Phone Number not Valid")
        : setPhoneError('');
};

const validateFullName = ({ fullName, setFullNameError }) => {
    return fullName && fullName.length < 5
        ? setFullNameError("Full Name is too short") :
        fullName && fullName.length > 50 ?
            setFullNameError("Full Name is too long") :
            setFullNameError('');
};

const validateMessage = ({ message, setMessageError }) => {
    return message && message.length < 5
        ? setMessageError("Message is too short") :
        message && message.length > 200 ?
            setMessageError("Message is too long") :
            setMessageError('');
};

export { validateEmail, validateFullName, validateMessage, validatePhone }