import React, { useContext } from 'react';
import { LanguageContext } from './LanguageContext';
import './LanguageSwitch.css'; // Import the CSS file

const LanguageSwitch = () => {
  const { isEnglish, toggleLanguage } = useContext(LanguageContext);

  return (
    <div className="switch-container">
      <label className="switch">
        <span className="language-label">ES</span>
        <input type="checkbox" checked={!isEnglish} onChange={toggleLanguage} />
        <span className="slider"></span>
        <span className="language-label">EN</span>
      </label>
    </div>
  );
};

export default LanguageSwitch;