import axios from "axios";

const URL = process.env.REACT_APP_SERVER_URL;

export const SendMessage = async ({
  fullName,
  email,
  phone,
  message,
  setSend,
}) => {
  try {
    const datas = { fullName, email, phone, message };
    let res = await axios.post(`https://cafecoquipr-api.onrender.com/send`, datas);
    if (res) {
      setSend(res.data);
    }
  } catch (error) {
    alert(error.response.data.msg);
  }
};