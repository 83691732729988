import React, { useState, useEffect, useContext } from "react";
import logo from "../images/cafecoqui.jpg"
import { validateFullName, validateEmail, validateMessage, validatePhone } from "./Validation";
import InlineError from "./InlineError";
import { SendMessage } from './API.js'
import { toast } from "react-hot-toast";
import Toast from "./Toast.js";
import { LanguageContext } from './LanguageContext';

function Contact() {

  const { getContent } = useContext(LanguageContext);
  const content = getContent();

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState();
  const [message, setMessage] = useState("");
  const [fullNameError, setFullNameError] = useState();
  const [emailError, setEmailError] = useState();
  const [phoneError, setPhoneError] = useState();
  const [messageError, setMessageError] = useState();
  const [send, setSend] = useState();




  useEffect(() => {

    validateFullName({ fullName, setFullNameError });
    validateEmail({ email, setEmailError });
    validatePhone({ phone, setPhoneError });
    validateMessage({ message, setMessageError });

    if (send) {
      toast.success(send.msg);
      setFullName("")
      setEmail("")
      setMessage("")
      setSend()
      setPhone("")
    }
  }, [fullName, email, phone, message, send]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (!fullNameError & !emailError & !phoneError & !messageError) {
      SendMessage({ fullName, email, phone, message, setSend })
    }
  };

  return (
    <>
      <Toast />
      <div className="container" id="contactUs">
        <div className="contact-info">
          <div className="contact-info3">

            <img className="rounded-image" src={logo} alt='' />
            <h2>CafeCoqui</h2>

          </div>
          <div className="contact-info2">

            <h3>{content.ContInfo}</h3>

            <p>{content.Phone}: (787) 616-6996</p>
            <p>POC: Edgardo Alfonzo Robles</p>
            <p>Email: cafecoquiservice@gmail.com</p>
          </div>
        </div>
        <form id="contact-form" onSubmit={submitHandler}>
          <h2>{content.contactUs}</h2>
          {/*FullName*/}
          <div className="form-group">
            <label for="name">{content.name}</label>
            <input value={fullName} onChange={(e) => setFullName(e.target.value)} type="text" name="name" id="name" required placeholder="John Doe" />
            {fullNameError && <InlineError error={fullNameError} />}
          </div>
          {/*email*/}
          <div className="form-group">
            <label for="email">Email</label>
            <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" name="email" id="email" required placeholder="johndoe@gmail.com" />
            {emailError && <InlineError error={emailError} />}
          </div>
          {/*Phone*/}
          <div className="form-group">
            <label for="phone">{content.PhoneL}</label>
            <input value={phone} onChange={(e) => setPhone(e.target.value)} type="tel" name="phone" id="phone" required placeholder="(123) 456-7890" />
            {phoneError && <InlineError error={phoneError} />}
          </div>
          {/*Message*/}
          <div className="form-group">
            <label for="message">{content.message}</label>
            <textarea value={message} onChange={(e) => setMessage(e.target.value)} name="message" id="message" required placeholder={content.typeHere}></textarea>
            {messageError && <InlineError error={messageError} />}
          </div>
          {/*Submit*/}
          <div className="form-group">
            <button type="submit">{content.send}</button>
          </div>
        </form>


      </div>
    </>
  )
}
export default Contact;