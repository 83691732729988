import React, { createContext, useState } from 'react';
import contentData from './content.json';

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [isEnglish, setIsEnglish] = useState(true);

  const toggleLanguage = () => {
    setIsEnglish(!isEnglish);
  };

  const getContent = () => {
    const language = isEnglish ? 'es' : 'en';
    return contentData[language];
  };

  return (
    <LanguageContext.Provider value={{ isEnglish, toggleLanguage, getContent }}>
      {children}
    </LanguageContext.Provider>
  );
};