import React, { useContext } from 'react';
import aboutImg from "../images/profile.jpg"
import { LanguageContext } from './LanguageContext';

function About() {


    const { getContent } = useContext(LanguageContext);
    const content = getContent();

    return (
        <div className="about-section" id="About">

            <div className="text-wrapper">
                <h2>{content.aboutUs}</h2>
                <p>&nbsp;&nbsp; {content.aboutUs1}<i><b>CafeCoqui</b> catering services.</i> {content.aboutUs2}</p>
                <p>&nbsp;&nbsp; {content.aboutUs3}<b><i>CafeCoqui</i></b> {content.aboutUs4} </p>
                <p>&nbsp;&nbsp; {content.aboutUs5}</p>
            </div>
            <div className="image-wrapper">
                <img className="profileImage" src={aboutImg} alt="About Image" />
            </div>
        </div>
    )
}

export default About;