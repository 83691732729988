import React, { useContext } from 'react';
import FeatureBox from './FeatureBox';
import featureimage from '../images/cafe.jpg';
import featureimage1 from '../images/edgardo.jpg';
import featureimage2 from '../images/catering.jpg';
import { LanguageContext } from './LanguageContext';

function Feature() {

    const { getContent } = useContext(LanguageContext);
    const content = getContent();

    return (
        <div id='features'>
            <div className="a-container">
                <FeatureBox image={featureimage} title={content.title1} content={content.content1} />
                <FeatureBox image={featureimage1} title={content.title2} content={content.content2} />
                <FeatureBox image={featureimage2} title={content.title3} content={content.content3} />
            </div>
        </div>
    )
}
export default Feature;